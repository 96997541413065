
export default {
    name: `LoginSSO`,
    options: {
      auth: false
    },
    data() {
        return { 
            providerID: this.$route.params.providerID
        }
    },
    mounted() {
        this.$auth.login({ params: { 
            sso_code: `${this.providerID}` 
        }})
    }
}
